<template>
  <wp-items-edit
    content-type="ask-dwg-articles"
    content-type-singular="ask-dwg-article"
    content-name="Ask DWG articles"
    content-name-singular="Ask DWG article"
  />
</template>

<script>
import WpItemsEdit from '@/views/wp-item/wp-items-edit/WpItemsEdit.vue'

export default {
  components: {
    WpItemsEdit,
  },
}
</script>

<style scoped>

</style>
