import { render, staticRenderFns } from "./AskDwgEdit.vue?vue&type=template&id=2d2cb6f6&scoped=true&"
import script from "./AskDwgEdit.vue?vue&type=script&lang=js&"
export * from "./AskDwgEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d2cb6f6",
  null
  
)

export default component.exports